import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"



const TermsAndConditionsPage = ({data, path}) => (
  <Layout title="Terms and Conditions" includeBanner={true} lightHeader={true} contentClassName="content-body"
    seoComponent={<SEO title="Terms and Conditions | Gummy Search" path={path} description="These terms and conditions outline the rules and regulations for the use of Gummy Search's Website"/>}>

    <h2 className="c5" id="h.xt84lkvhk1mz">
    <span className="c4">
    Overview</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    The gummysearch.com website and all of it's subdomains are operated by Top Cheddar LLC. Throughout the site, the terms &quot;we&quot;, &quot;us&quot; and &quot;our&quot; refer to Top Cheddar LLC. Top Cheddar LLC offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</span>
    </p>
     <p className="mb-4 c0"><span className="c2">
      GummySearch is not affiliated, associated, authorized, endorsed by, or in any way officially connected with Reddit.
    </span></p>
    <p className="mb-4 c0">
    <span className="c2">
    By visiting our site and/ or purchasing something from us, you engage in our &quot;Service&quot; and agree to be bound by the following terms and conditions (&quot;Terms of Service&quot;, &quot;Terms&quot;), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</span>
    </p>
    <p>
      You must be at least 18 years of age to use our website. By using our website [and by agreeing to these terms and conditions] you warrant and represent that you are at least 18 years of age.
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    Any new features or tools which are added to the current site shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.rchnkjdxftmn">
    <span className="c4">
    Site Usage Terms</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    You must not transmit any worms or viruses or any code of a destructive nature.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    A breach or violation of any of the Terms will result in an immediate termination of your Services.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.3yyuwqt7xutx">
    <span className="c4">
    General Conditions</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right to refuse service to anyone for any reason at any time.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.bjsq1mrc27ut">
    <span className="c4">
    Fair Use</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    Fair use defines Top Cheddar LLC exceptions to the rights protected by copyright laws.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    Fair use of gummysearch.com includes using copyrighted material:</span>
    </p>
    <ul className="c11 lst-kix_83nmawsk2sbp-0 start">
    <li className="c6">
    <span className="c2">
    In research</span>
    </li>
    <li className="c6">
    <span className="c2">
    In news reporting</span>
    </li>
    <li className="c6">
    <span className="c2">
    In citations</span>
    </li>
    <li className="c6">
    <span className="c2">
    In commentary</span>
    </li>
    </ul>
    <p className="mb-4 c0">
    <span className="c2">
    Fair use of gummysearch.com also includes:</span>
    </p>
    <ul className="c11 lst-kix_ikbb5hnclilx-0 start">
    <li className="c6">
    <span className="c2">
    Linking to gummysearch.com</span>
    </li>
    <li className="c6">
    <span className="c2">
    Adding gummysearch.com to search engines</span>
    </li>
    <li className="c6">
    <span className="c2">
    Library archiving gummysearch.com</span>
    </li>
    </ul>
    <hr/>
    
    <h2 className="c5" id="h.vvkiaaq2blrp">
    <span className="c4">
    Accuracy, Completeness and Timeliness of Information</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    We are not responsible if information made available on this site is not accurate, complete or current.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    If you discover that the site or its contents contains errors, please contact us so these can be corrected.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    Contact address:</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    fed@gummysearch.com</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.9xcjtqu2v2gb">
    <span className="c4">
    Modifications to the Service and Prices</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    Prices for our products are subject to change without notice.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.wll54iabsb55">
    <span className="c4">
    Products or Services</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    Certain products or services may be available exclusively online through the website.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right to discontinue any product or service at any time. Any offer for any product or service made on this site is void where prohibited.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    All sales are final, products purchased are not eligible to refunds.
    </span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.s9cy7gjqarrw">
    <span className="c4">
    Accuracy of Billing and Account Information</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e&#8209;mail and/or billing address/phone number provided at the time the order was made.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    You agree to provide current, complete and accurate purchase and account information for all purchases made on our site. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.r2z4kbiivuos">
    <span className="c4">
    Third-Party Content & Links</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    Certain content, products and services available via our Service may include materials from third-parties like Reddit.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    Third-party links on this site may direct you to third-party websites like Reddit that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party&#39;s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</span>
    </p>
    <p className="mb-4 c0">
      <span className="c2">We display third-party user-generated content on this Site from unaffiliated sources like Reddit. We take precaution to filter out NSFW, adult, or harmful content before displaying it on this Site. However, if that content is not tagged in that manner from our third-party sources, it may be displayed on this Site. By using this Site you agree you are 18 years of age or older, and accept that this content may be surfaced.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.vr1lgl1zgwae">
    <span className="c4">
    User Comments, Feedback, and Other Submissions</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    If, at our request, you send certain specific submissions (for example community entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, &#39;comments&#39;), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party&#39;s intellectual property or these Terms of Service.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e&#8209;mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We take no responsibility and assume no liability for any comments posted by you or any third-party.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.wyom68nupx03">
    <span className="c4">
    Personal Information</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c8">
    Your submission of personal information through the site is governed by our </span>
    <span className="c8 c10">
    <a className="c9" href="/privacy-policy">
    Privacy Policy</a>
    </span>
    <span className="c2">
    .</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.2vmj2tyyxzz2">
    <span className="c4">
    Errors, Inaccuracies and Omissions</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.x0lk5e6fsvmp">
    <span className="c4">
    Prohibited Uses</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.t7x3rqw4lf0b">
    <span className="c4">
    Disclaimer of Warranties; Limitation of Liability</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided &#39;as is&#39; and &#39;as available&#39; for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    In no case shall Top Cheddar LLC, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.dqx6a9vfir0k">
    <span className="c4">
    Indemnification</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    You agree to indemnify, defend and hold harmless Top Cheddar LLC and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys&#39; fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.jlpoaz9ol895">
    <span className="c4">
    Entire Agreement</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.c1fn4pn8iikw">
    <span className="c4">
    Governing Law</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Delaware.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.wfy4bjudlhv5">
    <span className="c4">
    Changes to Terms of Service</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    You can review the most current version of the Terms of Service at any time at this page.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes.</span>
    </p>
    <p className="mb-4 c0">
    <span className="c2">
    Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</span>
    </p>
    <hr/>
    
    <h2 className="c5" id="h.bserdshyht7t">
    <span className="c4">
    Contact Information</span>
    </h2>
    <p className="mb-4 c0">
    <span className="c2">
    If you have any questions or concerns regarding these Terms, please contact us at fed@gummysearch.com</span>
    </p>
    


    </Layout>
)


export default TermsAndConditionsPage
